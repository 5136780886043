import { getRsrcAccountRef, isResourceManaged } from 'features/resources'
import { isPrivateServer } from 'features/targets'
import { useObjectRef } from 'infra/redux'
import { createRsrcKey } from 'infra/redux/reducers'
import React, { createContext, useContext, useEffect, useState } from 'react'

const TagsCreatePolicyContext = createContext({
  resources: [],
  rsrcAttributesSpec: {}, //* All the attributes related to rsrc goes here. For Example, Iam Roles, Principal etc
  //* Follow the { [rsrc-key]:{ ...attributes } } pattern
  updateRsrcAttributes(key = '', attributes = {}) {},
  entities: [],
  setEntities(ref = []) {},
  selectedAccountRef: {},
  account: null,
  config: {
    startDate: null,
    endDate: null,
    name: '',
    comments: ''
  },
  /**
   * @typedef {{
   * name?:string
   * comments?: string
   * startDate?: Date
   * endDate?:Date
   * }} ConfigType
   *
   * @param {ConfigType} cfg
   */
  updateConfig(cfg) {}
})

/**
 *
 * @param {{
 * resources: any[],
 * children:any
 * selectedAccountRef:any
 * }} param0
 * @returns
 */
const TagsCreatePolicyProvider = ({ children, resources, selectedAccountRef }) => {
  //entities objects
  const [entities, setEntities] = useState([])
  const [rsrcAttributesSpec, setRsrcAttributesSpec] = useState({})

  const [config, setConfig] = useState({
    startDate: new Date(),
    endDate: null, //auto populated by component
    name: '',
    comments: ''
  })

  const { getObjectRef } = useObjectRef(['accountList'])

  const account = getObjectRef(selectedAccountRef)

  /**
   * @param {ConfigType} newConfig
   */
  const updateConfig = (newConfig) => {
    setConfig((s) => {
      return { ...s, ...newConfig }
    })
  }

  const updateRsrcAttributes = (key, attributes) => {
    setRsrcAttributesSpec((s) => {
      const attrbs = s[key] || {}
      return { ...s, [key]: { ...attrbs, ...attributes } }
    })
  }

  const getAccountRsrcs = () => {
    const addedRsrcsMap = {}

    return resources.filter((rsrc) => {
      if (addedRsrcsMap[createRsrcKey(rsrc)]) return
      addedRsrcsMap[createRsrcKey(rsrc)] = true
      if (!isResourceManaged(rsrc) && !['Application'].includes(rsrc.ObjectMeta.Kind)) return
      // applications don't have account info
      if (['Application'].includes(rsrc.ObjectMeta.Kind)) return true
      // private servers don't have account info
      if (rsrc.ObjectMeta.Kind === 'Server' && isPrivateServer(rsrc)) return true
      // account check filter
      else return createRsrcKey(account) === createRsrcKey(getRsrcAccountRef(rsrc))
    })
  }

  /**
   * Remove service accounts when the account type changes
   */
  useEffect(() => {
    if (!account) return
    setEntities((s) => s.filter((e) => e.ObjectMeta.Kind !== 'ServiceAccount'))
  }, [account?.Spec.Type])

  return (
    <TagsCreatePolicyContext.Provider
      value={{
        account,
        resources: getAccountRsrcs(),
        entities,
        setEntities,
        rsrcAttributesSpec,
        updateRsrcAttributes,
        config,
        updateConfig,
        selectedAccountRef
      }}
    >
      {children}
    </TagsCreatePolicyContext.Provider>
  )
}

export const useTagsCreatePolicyContext = () => useContext(TagsCreatePolicyContext)

export { TagsCreatePolicyProvider }
