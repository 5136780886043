import {
  faEnvelope,
  faExclamationTriangle,
  faKey,
  faLock,
  faShieldAlt,
  faTerminal,
  faUserGear
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { createDataSelectorHook } from 'infra/redux'
import moment from 'moment'
import { Button, DataTable, RiskIndicator, TargetIcon, Tooltip } from 'procyon-ui'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { ShimmerTableRow } from 'Utils/ShimmerEffect'
import _ from 'lodash'
import { filterRowsBySearchQuery, getRiskLevel } from 'features/IdentityAnalyzer/utils'
import { getAccount } from '../../utils'

const useslices = createDataSelectorHook(['iamUsers', 'userList', 'accountList'])
const UsersTable = ({ searchQuery }) => {
  const { slices } = useslices()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(true)
  const [tableRows, setTableRows] = useState([])
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 15,
    page: 0
  })

  const getFindingObj = (data) => {
    const cloudType = getAccount(slices.accountList, data?.Account?.RefID, 'type')
    if (cloudType === 'AWS') {
      return data?.AwsUserSpec
    } else {
      return data?.GcpUserSpec
    }
  }

  useEffect(() => {
    if (!slices || slices.iamUsers.length === 0 || slices.userList.length === 0) {
      setIsLoading(true)
    }

    if (slices?.iamUsers?.length > 0 && tableRows.length === 0) {
      const rows = slices.iamUsers
        .slice()
        .map((obj, index) => {
          const transformedObj = {
            id: index + 1,
            platform: getAccount(slices.accountList, obj.Spec.Account.RefID, 'type'),
            email: obj.Spec.Name || obj.Spec.UserName,
            createdate:
              obj.Spec.AwsUserSpec.CreatedAt !== '' &&
              moment(obj.Spec.AwsUserSpec.CreatedAt).format('MM-DD-YY HH:mm:ss'),
            lastused:
              obj.Spec.LastActivity !== ''
                ? moment(obj?.Spec?.LastActivity).format('MM-DD-YY HH:mm:ss')
                : '',
            riskResources: obj.Spec.RiskScore,
            account: getAccount(slices.accountList, obj.Spec.Account.RefID, 'name'),
            accountRef: obj.ObjectMeta.ID,
            finding: getFindingObj(obj?.Spec)
          }

          const findingsLabels = generateFindingsLabels({ row: transformedObj })
          transformedObj.findingsLength = findingsLabels.length

          return transformedObj
        })
        .sort((a, b) => {
          if (a.findingsLength > b.findingsLength) return -1
          if (a.findingsLength < b.findingsLength) return 1

          const dateA = moment(a.createdate, 'MM-DD-YY HH:mm:ss')
          const dateB = moment(b.createdate, 'MM-DD-YY HH:mm:ss')
          if (dateA.isBefore(dateB)) return 1
          if (dateA.isAfter(dateB)) return -1

          return 0
        })
      setTableRows(rows)
      setIsLoading(false)
    }
  }, [slices])

  const handleRedirectRemedyDetails = (id, cloudType) => {
    history.push(
      `/admin/idaanalyzer/IamUser/${encodeURIComponent(id)}/${encodeURIComponent(cloudType)}`
    )
  }

  const handleGraphRedirection = (accountRef) => {
    history.push(`/admin/idaanalyzer/idagraph/IamUser/${encodeURIComponent(accountRef)}`)
  }

  const getUserName = (email) => {
    const userName = slices.userList.find((user) => user.Spec.EmailID === email)
    if (userName) {
      return (
        <p
          className='font-medium underline cursor-pointer'
          onClick={() => handleUserRedirection(userName.Spec.EmailID)}
        >
          {userName.Spec.EmailID}
        </p>
      )
    } else {
      return <p className='font-medium'>{email}</p>
    }
  }

  const handleUserRedirection = (email) => {
    history.push(`/admin/users/${email}`)
  }

  const filteredRows = filterRowsBySearchQuery(tableRows, searchQuery)

  const generateFindingsLabels = (data) => {
    const userData = data.row.finding
    const labels = []
    const addedLabels = new Set()

    // MFA Enabled/Disabled Label
    if (userData.MFAEnabled === false && !addedLabels.has('MFAEnabled')) {
      labels.push(
        <Tooltip title='Multiple Authenticator Not Enabled' key='MFAEnabled'>
          <span className='bg-white shadow-md rounded-full w-[30px] h-[30px] flex items-center justify-center mr-3'>
            <FontAwesomeIcon icon={faShieldAlt} color='red' className='cursor-pointer' />
          </span>
        </Tooltip>
      )
      addedLabels.add('MFAEnabled')
    }

    // Console Access Label
    if (userData.ConsoleAccess === true && !addedLabels.has('ConsoleAccess')) {
      labels.push(
        <Tooltip title='Console Access Enabled' key='ConsoleAccess'>
          <span className='bg-white shadow-md rounded-full w-[30px] h-[30px] flex items-center justify-center mr-3'>
            <FontAwesomeIcon icon={faTerminal} className='cursor-pointer' />
          </span>
        </Tooltip>
      )
      addedLabels.add('ConsoleAccess')
    }

    // Analyzer Findings Labels
    const analyzerFindings = userData.AnalyzerFindings?.AnalyzerFindingMap || {}
    Object.keys(analyzerFindings).forEach((key) => {
      const finding = analyzerFindings[key]

      // UnusedPermission Label
      if (finding.Type === 'UnusedPermission' && !addedLabels.has('UnusedPermission')) {
        labels.push(
          <Tooltip title='Unused Permission' key={`UnusedPermission-${key}`}>
            <span className='bg-white shadow-md rounded-full w-[30px] h-[30px] flex items-center justify-center mr-3'>
              <FontAwesomeIcon icon={faExclamationTriangle} />
            </span>
          </Tooltip>
        )
        addedLabels.add('UnusedPermission')
      }

      // UnusedIAMUserPassword Label (with Tooltip on hover)
      if (finding.Type === 'UnusedIAMUserPassword' && !addedLabels.has('UnusedIAMUserPassword')) {
        labels.push(
          <Tooltip key={`UnusedIAMUserPassword-${key}`} title='Unused Password'>
            <span className='bg-white shadow-md rounded-full w-[30px] h-[30px] flex items-center justify-center mr-3'>
              <FontAwesomeIcon icon={faLock} />
            </span>
          </Tooltip>
        )
        addedLabels.add('UnusedIAMUserPassword')
      }
    })

    // Analyzer Findings Labels for GCP
    if (data.row.platform === 'GCP') {
      const gcpAnalyzerFindings = Object.keys(userData?.AnalyzerFindings?.Map || {}).length
        ? Object.fromEntries(
            Object.entries(userData?.AnalyzerFindings?.Map).map(([key, value]) => {
              try {
                return [key, JSON.parse(value)] // Parse each value
              } catch (error) {
                console.error(`Failed to parse value for key "${key}":`, error)
                return [key, value] // Use original value if parsing fails
              }
            })
          )
        : {}

      gcpAnalyzerFindings &&
        Object.keys(gcpAnalyzerFindings).forEach((key) => {
          const finding = gcpAnalyzerFindings[key]

          // UnusedPermission Label (Clickable)
          if (finding.Type === 'UnusedPermission' && !addedLabels.has('UnusedPermission')) {
            labels.push(
              <Tooltip title='Unused Permission' key={`UnusedPermission-${key}`}>
                <span className='bg-white shadow-md rounded-full w-[30px] h-[30px] flex items-center justify-center mr-3'>
                  <FontAwesomeIcon icon={faExclamationTriangle} />
                </span>
              </Tooltip>
            )
            addedLabels.add('UnusedPermission')
          }

          // UnusedIAMRole label
          if (finding.Type === 'UnusedIAMRole' && !addedLabels.has('UnusedIAMRole')) {
            labels.push(
              <Tooltip key={`UnusedIAMRole-${key}`} title='Unused Iam Role'>
                <span className='bg-white shadow-md rounded-full w-[30px] h-[30px] flex items-center justify-center mr-3'>
                  <FontAwesomeIcon icon={faUserGear} />
                </span>
              </Tooltip>
            )
            addedLabels.add('UnusedIAMRole')
          }
        })
    }

    // Find the Most Recent Last Access Date from Access Keys
    const accessKeys = userData.AccessKeys?.AccessKeys || []
    let mostRecentLastAccessed = 'Unknown'

    if (accessKeys.length === 1) {
      // Directly access the LastAccessed date if there is only one access key
      const singleAccessKey = accessKeys[0]
      const analyzerMap = singleAccessKey?.AnalyzerFindings?.AnalyzerFindingMap || {}

      // Extract LastAccessed date directly
      Object.keys(analyzerMap).forEach((key) => {
        const finding = analyzerMap[key]
        const details = JSON.parse(finding.Details || '[]')

        // Get the first available LastAccessed date in details
        const lastAccessed = details.find((item) => item.Value.LastAccessed)?.Value.LastAccessed

        if (lastAccessed) {
          mostRecentLastAccessed = lastAccessed
        }
      })

      // Push the label directly for a single access key with LastAccessed date
      labels.push(
        <Tooltip title={`Access Key`} key='AccessKeyLastAccessedSingle'>
          <span className='bg-white shadow-md rounded-full w-[30px] h-[30px] flex items-center justify-center mr-3'>
            <FontAwesomeIcon icon={faKey} />
          </span>
        </Tooltip>
      )
      addedLabels.add('AccessKeyLastAccessedSingle')
    } else {
      // Process as usual when there are multiple access keys
      accessKeys.forEach((accessKey) => {
        const analyzerMap = accessKey?.AnalyzerFindings?.AnalyzerFindingMap || {}

        Object.keys(analyzerMap).forEach((key) => {
          const finding = analyzerMap[key]
          const details = JSON.parse(finding.Details || '[]')

          // Extract LastAccessed dates from details and find the most recent one
          const lastAccessedDates = details
            .map((item) => item.Value.LastAccessed)
            .filter((date) => !!date)
            .sort((a, b) => new Date(String(b)).getTime() - new Date(String(a)).getTime())

          // Update the most recent LastAccessed if found
          if (lastAccessedDates.length > 0) {
            const latestDate = lastAccessedDates[0]
            if (
              mostRecentLastAccessed === 'Unknown' ||
              new Date(latestDate) > new Date(mostRecentLastAccessed)
            ) {
              mostRecentLastAccessed = latestDate
            }
          }
        })
      })

      // Push the label with the most recent last access date if multiple keys exist
      if (mostRecentLastAccessed !== 'Unknown' && !addedLabels.has('AccessKeyLastAccessed')) {
        labels.push(
          <Tooltip title={`Access Key`} key='AccessKeyLastAccessed'>
            <span className='bg-white shadow-md rounded-full w-[30px] h-[30px] flex items-center justify-center mr-3'>
              <FontAwesomeIcon icon={faKey} />
            </span>
          </Tooltip>
        )
        addedLabels.add('AccessKeyLastAccessed')
      }
    }

    return labels
  }

  return (
    <>
      <DataTable
        autoHeight={true}
        pageSizeOptions={[5, 10, 25, 50, 100]}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        columns={[
          {
            field: 'email',
            headerName: 'Email',
            renderCell: (param) =>
              isLoading ? (
                <ShimmerTableRow />
              ) : (
                <>
                  <span className='mr-2'>
                    <FontAwesomeIcon icon={faEnvelope} />
                  </span>
                  <Tooltip title={getUserName(param.value)}>{getUserName(param.value)}</Tooltip>
                </>
              ),
            width: 280
          },
          {
            field: 'createdate',
            headerName: 'Creation Date',
            renderCell: (param) =>
              isLoading ? (
                <ShimmerTableRow />
              ) : (
                <Tooltip title='Date and Time Format Is MM-DD-YY HH-MM-SS'>
                  <p className='font-medium'>{param.value}</p>
                </Tooltip>
              ),
            width: 170
          },
          {
            field: 'lastused',
            headerName: 'Last Used',
            renderCell: (param) =>
              isLoading ? (
                <ShimmerTableRow />
              ) : (
                <Tooltip title='Date and Time Format Is MM-DD-YY HH-MM-SS'>
                  <p className='font-medium'>{param.value}</p>
                </Tooltip>
              ),
            width: 170
          },
          {
            field: 'account',
            headerName: 'Account',
            renderCell: (param) =>
              isLoading ? <ShimmerTableRow /> : <p className='font-medium'>{param.value}</p>,
            width: 220
          },
          {
            field: 'platform',
            headerName: 'Platform',
            renderCell: (param) =>
              isLoading ? <ShimmerTableRow /> : <TargetIcon width='30px' type={param.value} />,
            width: 140
          },
          {
            field: 'riskResources',
            headerName: 'Risk',
            renderCell: (params) =>
              isLoading ? (
                <ShimmerTableRow />
              ) : (
                <RiskIndicator variant={getRiskLevel(params.value)} />
              ),
            width: 140
          },
          {
            field: 'graph',
            headerName: 'Graph',
            renderCell: (param) =>
              isLoading ? (
                <ShimmerTableRow />
              ) : (
                <>
                  <div className='bg-white shadow-md rounded-full w-[35px] h-[35px] flex items-center justify-center'>
                    <img
                      onClick={() => handleGraphRedirection(param.row.accountRef)}
                      src='img/icons/organization-chart.png'
                      alt=''
                      className='w-5 cursor-pointer'
                    />
                  </div>
                </>
              ),
            width: 130
          },
          {
            field: 'finding',
            headerName: 'Findings',
            renderCell: (param) =>
              isLoading ? <ShimmerTableRow /> : <>{generateFindingsLabels(param)}</>,
            width: 200
          },
          {
            field: 'accountRef',
            headerName: 'Recommended Action',
            renderCell: (param) =>
              isLoading ? (
                <ShimmerTableRow />
              ) : (
                <>
                  {generateFindingsLabels(param).length > 0 && (
                    <span
                      onClick={() => handleRedirectRemedyDetails(param.value, param.row.platform)}
                    >
                      <Button variant='secondary'>Action</Button>
                    </span>
                  )}
                </>
              ),
            width: 220
          }
        ]}
        rows={
          isLoading
            ? Array(1).fill({
                id: '',
                name: '',
                email: '',
                createdate: '',
                lastused: '',
                platforms: '',
                riskResources: ''
              })
            : filteredRows
        }
      />
    </>
  )
}

export { UsersTable }
