import { useState, useRef, useCallback } from 'react'
import { fetchGraphData, fetchNextGraphData, getIsCollapsibleState } from '../utils/index'
import JSONbig from 'json-bigint'
import {
  generateUniqueKey,
  getNodeKey,
  getNodeName,
  getNodeType,
  getNodeTypeName
} from 'features/IdentityAnalyzer/utils'
import { reduxApiClient } from 'infra'

function shallowArrayEqual(arrA, arrB) {
  if (arrA.length !== arrB.length) return false
  for (let i = 0; i < arrA.length; i++) {
    if (arrA[i] !== arrB[i]) return false
  }
  return true
}

export const useGraph = (user, userObj, kind, accountRefID) => {
  const [responseGraphData, setResponseGraphData] = useState([])
  const [nodesMeta, setNodesMeta] = useState({})
  const [nodes, setNodes] = useState([])
  const [edges, setEdges] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const nodeSet = useRef(new Map())
  const parentChildMap = useRef(new Map())
  const accountRefIDMap = useRef(new Map())

  const getGraphData = useCallback(async () => {
    setIsLoading(true)
    try {
      const data = await fetchGraphData(user, userObj, accountRefID, kind)
      setResponseGraphData(data)
    } finally {
      setIsLoading(false)
    }
  }, [user, userObj, accountRefID, kind])

  const getNextGraphData = useCallback(
    async (refID) => {
      setIsLoading(true)
      try {
        const data = await fetchNextGraphData(user, userObj, refID, kind)
        setResponseGraphData((prevData) => [...prevData, ...data])
      } finally {
        setIsLoading(false)
      }
    },
    [user, userObj, kind]
  )

  const getIamUserNextGraphData = async (refID) => {
    const payload = {
      ObjectMeta: {
        Tenant: user.tenant,
        Namespace: user.org,
        Name: userObj?.Spec?.Name
      },
      Spec: {
        Type: 'GET_IDENTITY_GRAPH',
        IdentityGraphSpec: {
          RefID: refID,
          RefKind: kind === 'IamFederatedUser' ? 'IamRole' : kind,
          Level: 4
        }
      }
    }

    try {
      const response = await reduxApiClient('analyze-identity').create(payload)
      return JSONbig.parse(response?.Spec?.IdentityGraphSpec?.Graph)
    } catch (error) {
      console.error('Error fetching IamUser graph data:', error)
      return null
    }
  }

  const getNextIamUserPath = async (iamUserId) => {
    const iamUserData = await getIamUserNextGraphData(iamUserId)
    if (iamUserData) {
      setResponseGraphData((prev) => [...prev, ...iamUserData])
      requestAnimationFrame(() => {
        setNodesMeta((prevMeta) => ({
          ...prevMeta,
          [getNodeKey(iamUserId, kind)]: {
            ...prevMeta[getNodeKey(iamUserId, kind)],
            isLoading: false,
            isExpanded: true,
            isCollapsed: false
          }
        }))
      })
    }
  }

  const getExpandableRsrc = async (accountRef, rsrcName) => {
    const payload = {
      ObjectMeta: {
        Tenant: user.tenant,
        Namespace: user.org,
        Name: userObj?.Spec?.Name
      },
      Spec: {
        Type: 'GET_RESOURCES',
        ResourceSpec: {
          Account: {
            RefID: accountRef.Id,
            RefKind: 'Account'
          },
          limit: 50,
          offset: 0,
          ResourceType: rsrcName
        }
      }
    }

    try {
      const response = await reduxApiClient('analyze-identity').create(payload)
      return JSONbig.parse(response?.Spec?.ResourceSpec?.Graph)
    } catch (error) {
      console.error('Error fetching resource data:', error)
      return null
    }
  }

  const getNextResourcesPath = async (id, accountRef, rsrcName, nodeRefKind, properties) => {
    const resourceData = await getExpandableRsrc(accountRef, rsrcName)
    if (resourceData) {
      const newEdges = resourceData.map((node) => ({
        FromNode: {
          RefID: id,
          RefKind: nodeRefKind,
          Type: rsrcName,
          Name: nodeSet.current.get(id)?.label || `Node ${id}`
        },
        ToNode: {
          ...node,
          Type: rsrcName
        }
      }))

      setResponseGraphData((prevData) => [...prevData, ...newEdges])
      requestAnimationFrame(() => {
        setNodesMeta((prevMeta) => ({
          ...prevMeta,
          [id]: {
            ...prevMeta[id],
            isLoading: false,
            isExpanded: true,
            isCollapsed: false
          }
        }))
      })
    }
  }

  const handleExpandNodeCall = useCallback(
    async (node, collapsible) => {
      const { RefID, RefKind, Name, Properties } = node
      const nodeKey = getNodeKey(RefID, RefKind)
      const meta = nodesMeta[nodeKey] || {}
      const isExpanded = meta.isExpanded || false

      setNodesMeta((prev) => ({
        ...prev,
        [nodeKey]: {
          ...prev[nodeKey],
          isLoading: true
        }
      }))

      try {
        if (isExpanded) {
          // Collapse
          setNodesMeta((prev) => ({
            ...prev,
            [nodeKey]: {
              ...prev[nodeKey],
              isExpanded: false,
              isCollapsed: true,
              isLoading: false
            }
          }))
        } else {
          // Expand
          const childSet = parentChildMap.current.get(nodeKey) || new Set()
          const alreadyHasChildren = childSet.size > 0

          if (
            RefID === 'dummy-user1' ||
            RefID === 'dummy-aws' ||
            RefID === 'dummy-gcp' ||
            RefID === 'dummy-azure'
          ) {
            setNodesMeta((prev) => ({
              ...prev,
              [nodeKey]: {
                ...prev[nodeKey],
                isExpanded: true,
                isCollapsed: false,
                isLoading: false
              }
            }))
            return // Exit early, no API call needed
          }

          if (!alreadyHasChildren) {
            if (
              RefKind === 'IamUser' ||
              RefKind === 'IamGroup' ||
              RefKind === 'IamRole' ||
              RefKind === 'IamFederatedUser'
            ) {
              await getNextIamUserPath(RefID.toString())
            } else if (RefKind === 'ExpandableNode') {
              const nodeData = nodeSet.current.get(nodeKey)
              const accountRef = nodeData?.accountRef
              await getNextResourcesPath(RefID, accountRef, Name, RefKind, Properties)
            }

            setNodesMeta((prev) => ({
              ...prev,
              [nodeKey]: {
                ...prev[nodeKey],
                hasFetched: true
              }
            }))
          }

          setNodesMeta((prev) => ({
            ...prev,
            [nodeKey]: {
              ...prev[nodeKey],
              isExpanded: true,
              isCollapsed: false,
              isLoading: false
            }
          }))
        }
      } catch (error) {
        console.error('Error during expand/collapse:', error)
        setNodesMeta((prev) => ({
          ...prev,
          [nodeKey]: {
            ...prev[nodeKey],
            isLoading: false
          }
        }))
      }
    },
    [nodesMeta, getNextIamUserPath, getNextResourcesPath]
  )

  const buildGraphData = useCallback(() => {
    setIsLoading(true)

    nodeSet.current.clear()
    parentChildMap.current.clear()
    accountRefIDMap.current.clear()

    const localParentChildMap = new Map()
    const allEdges = []

    const nodeLayers = {
      DummyUser: [],
      DummyCloud: [],
      Account: [],
      IamUser: [],
      IamGroup: [],
      IamFederatedUser: [],
      IamRole: [],
      IamServiceAccount: [],
      IamAction: [],
      IamPolicy: [],
      AccessKey: [],
      Resources: [],
      Organization: [],
      'Folder|0': [],
      'Folder|1': [],
      'Folder|2': [],
      'Folder|3': [],
      'Folder|4': [],
      'Folder|5': [],
      'Folder|6': [],
      Project: [],
      'Compute Instance': [],
      ExpandableNode: [],
      EmptyNode: [],
      GcpResource: [],
      AwsResource: []
    }

    const addToLayer = (refKind, nodeData) => {
      if (kind === 'IamUser' && refKind === 'AccessKey') {
        nodeLayers.IamGroup.push(nodeData)
        nodeLayers.AccessKey = nodeLayers.AccessKey.filter((n) => n.id !== nodeData.id)
      } else {
        if (!nodeLayers[refKind]) {
          nodeLayers[refKind] = []
        }
        nodeLayers[refKind].push(nodeData)
      }
    }

    // Create "dummy-user" for IamUser
    if (kind === 'IamUser') {
      const dummyUserKey = getNodeKey('dummy-user1', kind)
      if (!nodeSet.current.has(dummyUserKey)) {
        const dummyUserNode = {
          id: dummyUserKey,
          label: userObj?.Spec?.Name?.toString() || userObj?.Spec?.UserName?.toString(),
          key: generateUniqueKey('dummy-user1', kind, '1', '1'),
          type: 'USER',
          collapsible: true, // All nodes are expandable
          description: '',
          onExpand: () => handleExpandNodeCall({ RefID: 'dummy-user1', RefKind: kind }, true),
          data: { meta: nodesMeta[dummyUserKey] || {} }
        }
        addToLayer('DummyUser', dummyUserNode)
        nodeSet.current.set(dummyUserKey, dummyUserNode)
      }
    }

    // Handle "cloud account" grouping logic
    const cloudAccounts = responseGraphData.filter(
      (item) =>
        item.FromNode.RefKind === 'Account' &&
        (item.FromNode.Properties?.ACCOUNT_TYPE === 'AWS' ||
          item.FromNode.Properties?.ACCOUNT_TYPE === 'GCP' ||
          item.FromNode.Properties?.ACCOUNT_TYPE === 'AZURE')
    )

    const groupedAccounts = cloudAccounts.reduce((acc, item) => {
      const acctType = item.FromNode.Properties?.ACCOUNT_TYPE
      if (!acc[acctType]) acc[acctType] = []
      acc[acctType].push(item)
      return acc
    }, {})

    Object.keys(groupedAccounts).forEach((acctType) => {
      const accounts = groupedAccounts[acctType]
      if (accounts.length === 1 && kind === 'IamUser') {
        const { FromNode, ToNode } = accounts[0]
        const fromKey = getNodeKey(FromNode.RefID, FromNode.RefKind)
        if (!nodeSet.current.has(fromKey)) {
          const fromData = {
            id: fromKey,
            key: generateUniqueKey(FromNode.RefID, FromNode.RefKind, ToNode.RefID, ToNode.RefKind),
            label: 'Account',
            type: acctType,
            collapsible: true, // All nodes are expandable
            description: FromNode.Name?.toString() || '',
            onExpand: () => handleExpandNodeCall(FromNode, true),
            data: { meta: nodesMeta[fromKey] || {} }
          }
          addToLayer(FromNode.RefKind, fromData)
          nodeSet.current.set(fromKey, fromData)
        }

        const dummyUserKey = getNodeKey('dummy-user1', kind)
        if (!localParentChildMap.has(dummyUserKey)) {
          localParentChildMap.set(dummyUserKey, new Set())
        }
        localParentChildMap.get(dummyUserKey).add(fromKey)
        allEdges.push({ startID: dummyUserKey, endID: fromKey })
      } else if (accounts.length >= 1 && kind === 'IamUser') {
        const dummyNodeKey = getNodeKey(`dummy-${acctType.toLowerCase()}`, acctType)
        if (!nodeSet.current.has(dummyNodeKey)) {
          const dummyNode = {
            id: dummyNodeKey,
            label: '',
            type: acctType,
            key: generateUniqueKey(`dummy-${acctType.toLowerCase()}`, acctType, '2', '2'),
            collapsible: true, // All nodes are expandable
            description: `${acctType} accounts`,
            onExpand: () =>
              handleExpandNodeCall(
                { RefID: `dummy-${acctType.toLowerCase()}`, RefKind: acctType },
                true
              ),
            data: { meta: nodesMeta[dummyNodeKey] || {} }
          }
          addToLayer('DummyCloud', dummyNode)
          nodeSet.current.set(dummyNodeKey, dummyNode)
        }

        const dummyUserKey = getNodeKey('dummy-user1', kind)
        if (!localParentChildMap.has(dummyUserKey)) {
          localParentChildMap.set(dummyUserKey, new Set())
        }
        localParentChildMap.get(dummyUserKey).add(dummyNodeKey)
        allEdges.push({ startID: dummyUserKey, endID: dummyNodeKey })

        accounts.forEach(({ FromNode, ToNode }) => {
          const fromKey = getNodeKey(FromNode.RefID, FromNode.RefKind)
          if (!nodeSet.current.has(fromKey)) {
            const fromData = {
              id: fromKey,
              key: generateUniqueKey(
                FromNode.RefID,
                FromNode.RefKind,
                ToNode.RefID,
                ToNode.RefKind
              ),
              label: 'Account',
              type: acctType,
              collapsible: true, // All nodes are expandable
              description: FromNode.Name?.toString() || `Desc for ${FromNode.RefID}`,
              onExpand: () => handleExpandNodeCall(FromNode, true),
              data: { meta: nodesMeta[fromKey] || {} }
            }
            addToLayer(FromNode.RefKind, fromData)
            nodeSet.current.set(fromKey, fromData)
          }
          if (!localParentChildMap.has(dummyNodeKey)) {
            localParentChildMap.set(dummyNodeKey, new Set())
          }
          localParentChildMap.get(dummyNodeKey).add(fromKey)
          allEdges.push({ startID: dummyNodeKey, endID: fromKey })
        })
      }
    })

    responseGraphData.forEach((item) => {
      const { FromNode, ToNode } = item
      const fromKey = getNodeKey(FromNode.RefID, FromNode.RefKind)
      const toKey = getNodeKey(ToNode.RefID, ToNode.RefKind)
      if (!localParentChildMap.has(fromKey)) {
        localParentChildMap.set(fromKey, new Set())
      }
      localParentChildMap.get(fromKey).add(toKey)
    })

    responseGraphData.forEach((item) => {
      const { FromNode, ToNode } = item
      const fromKey = getNodeKey(FromNode.RefID, FromNode.RefKind)
      const toKey = getNodeKey(ToNode.RefID, ToNode.RefKind)

      let fromRef = accountRefIDMap.current.get(JSONbig.stringify(FromNode.RefID))
      if (!fromRef && FromNode.RefKind === 'Account') {
        fromRef = {
          Id: JSONbig.stringify(FromNode.RefID),
          Type: FromNode?.Properties?.ACCOUNT_TYPE
        }
        accountRefIDMap.current.set(JSONbig.stringify(FromNode.RefID), fromRef)
      }
      let toRef = accountRefIDMap.current.get(JSONbig.stringify(ToNode.RefID))
      if (!toRef) {
        toRef = fromRef
        if (toRef) {
          accountRefIDMap.current.set(JSONbig.stringify(ToNode.RefID), toRef)
        }
      }

      if (!nodeSet.current.has(fromKey)) {
        const childrenCount = localParentChildMap.get(fromKey)?.size ?? 0
        const collapsible = getIsCollapsibleState(FromNode.RefKind, kind) || childrenCount > 0

        const fromData = {
          id: fromKey,
          key: generateUniqueKey(FromNode.RefID, FromNode.RefKind, ToNode.RefID, ToNode.RefKind),
          label: getNodeTypeName(FromNode, kind),
          type: getNodeType(FromNode, FromNode?.Properties?.ACCOUNT_TYPE),
          collapsible: collapsible, // All nodes are expandable
          accountRef: fromRef,
          onExpand: () => handleExpandNodeCall(FromNode, true),
          description: getNodeName(FromNode),
          data: { meta: nodesMeta[fromKey] || {} }
        }

        if (
          FromNode.RefKind === 'GcpResource' ||
          (FromNode.RefKind === 'AzureResource' && FromNode.Properties?.Label)
        ) {
          addToLayer(FromNode.Properties.Label, fromData)
        } else {
          addToLayer(FromNode.RefKind, fromData)
        }
        nodeSet.current.set(fromKey, fromData)
      }

      if (!nodeSet.current.has(toKey)) {
        const childrenCount = localParentChildMap.get(toKey)?.size ?? 0
        const collapsible = getIsCollapsibleState(ToNode.RefKind, kind) || childrenCount > 0
        const toData = {
          id: toKey,
          key: generateUniqueKey(ToNode.RefID, ToNode.RefKind, FromNode.RefID, FromNode.RefKind),
          label: getNodeTypeName(ToNode, kind),
          type: getNodeType(ToNode, ToNode?.Properties?.ACCOUNT_TYPE),
          collapsible: collapsible, // All nodes are expandable
          accountRef: toRef,
          onExpand: () => handleExpandNodeCall(ToNode, true),
          description: getNodeName(ToNode),
          data: { meta: nodesMeta[toKey] || {} }
        }
        if (
          ToNode.RefKind === 'GcpResource' ||
          (ToNode.RefKind === 'AzureResource' && ToNode?.Properties?.Label)
        ) {
          addToLayer(ToNode.Properties.Label, toData)
        } else {
          addToLayer(ToNode.RefKind, toData)
        }
        nodeSet.current.set(toKey, toData)
      }

      allEdges.push({ startID: fromKey, endID: toKey })
    })

    const visited = new Set()
    const visibleEdges = []

    const allKeys = Array.from(nodeSet.current.keys())
    const allChildren = new Set()
    for (const [parent, childSet] of localParentChildMap.entries()) {
      for (const c of childSet) {
        allChildren.add(c)
      }
    }
    const rootKeys = allKeys.filter((k) => !allChildren.has(k))

    const queue = [...rootKeys]
    while (queue.length) {
      const currentKey = queue.shift()
      if (visited.has(currentKey)) continue
      visited.add(currentKey)

      const currentNode = nodeSet.current.get(currentKey)
      if (!currentNode) continue

      const meta = nodesMeta[currentKey] || {}
      const isExpanded = !!meta.isExpanded
      const childSet = localParentChildMap.get(currentKey) || new Set()

      if (currentNode.collapsible && !isExpanded) {
        // Node is collapsible and not expanded, so children are hidden
      } else {
        for (const childKey of childSet) {
          visibleEdges.push({ startID: currentKey, endID: childKey })
          queue.push(childKey)
        }
      }
    }

    const finalLayerArray = Object.values(nodeLayers).map((layerArr) =>
      layerArr.filter((nodeData) => visited.has(nodeData.id))
    )
    const cleanGraphData = finalLayerArray.filter((layer) => layer.length > 0)

    requestAnimationFrame(() => {
      setIsLoading(false)

      setEdges((prevEdges) => {
        if (prevEdges.length === visibleEdges.length) {
          if (shallowArrayEqual(prevEdges, visibleEdges)) {
            return prevEdges
          }
        }
        return visibleEdges
      })

      setNodes((prevNodes) => {
        if (prevNodes.length !== cleanGraphData.length) {
          return cleanGraphData
        }

        for (let i = 0; i < cleanGraphData.length; i++) {
          if (cleanGraphData[i] !== prevNodes[i]) {
            return cleanGraphData
          }
        }

        return prevNodes
      })
    })
  }, [responseGraphData, nodesMeta, userObj, kind])

  return {
    responseGraphData,
    nodesMeta,
    nodes,
    edges,
    setNodesMeta,
    getGraphData,
    getNextGraphData,
    buildGraphData,
    nodeSet,
    accountRefIDMap,
    isLoading,
    handleExpandNodeCall
  }
}
