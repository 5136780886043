import { Typography } from '@material-ui/core'
import { PortableWifiOff } from '@material-ui/icons'
import NetworkError from 'Components/NetworkError'
import { API, useDispatch, useSelector } from 'Core'
import { prepareUserObject } from 'Utils/AuthHelpers'
import { debug } from 'Utils/DebugHelper'
import { loginUser } from 'infra/redux/actions'
import { userpreferencesSlice } from 'infra/redux/reducers'
import localforage from 'localforage'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import useMultiDispatch from '../useMultiDispatch'
import { useMultiOrg } from '../useMultiOrg'
import useNetworkPolling from '../useNetworkPolling'

export const BootContext = React.createContext({ bootComplete: false })

function BootProvider(props) {
  const dispatch = useDispatch()
  const history = useHistory()
  // @ts-ignore
  const user = useSelector((state) => state.user)
  const [bootComplete, setBootComplete] = useState(false)
  const fetched = useRef(false)

  const { changeOrganization } = useMultiOrg()
  const { selectDispatch } = useMultiDispatch([])

  const handleAgentContainer = useCallback(() => {
    try {
      const params = new URLSearchParams(window.location.search)
      const isAgentContainer = params.get('agent')
      if (isAgentContainer) {
        debug.setContainer('agent')
      } else {
        debug.setContainer('browser')
      }
    } catch (error) {
      console.error('error in handleAgentContainer', error)
      debug.setContainer('browser')
    }
  }, [])

  const init = useCallback(async () => {
    /** Set current container type from url to localstorage (browser|agent)    */
    handleAgentContainer()
    const ignoredHistoryRoutes = ['/ui/register', '/ui/webauthn']
    const ignoreCurrentRoute = ignoredHistoryRoutes.includes(window.location.pathname)
    /** Initiate login process */
    const currentUrl = window.location.pathname
    const isRedirectIgnoredURL =
      currentUrl.includes('signupverify') ||
      currentUrl.includes('signup') ||
      currentUrl.includes('webauthn')
    const isAgentPage =
      window.location.host?.includes('login') || currentUrl.includes('tenant-login')

    // check agentless using localstore user awsurl
    const userString = localStorage.getItem('user')
    const userObject = userString ? JSON.parse(userString) : null
    const isAgent = userObject?.awsurl?.includes('signin') || false

    try {
      /** Makes api call to get currenly logged in user's DB Object  */
      const response = await API.checkAuth()
      if (response && response.data) {
        const user = prepareUserObject(response)
        dispatch(loginUser({ ...user }))
        if (isRedirectIgnoredURL && !ignoreCurrentRoute) history.push('/')
        return
      }
      /** After all the logic, send to signup. The user is not authenticated */

      // handle agentless login page if url has login string then redirect below route
      if (isAgentPage || isAgent) {
        history.push('/tenant-login')
      } else if (!isRedirectIgnoredURL && !ignoreCurrentRoute) {
        history.push('/signup')
      }
    } catch (error) {
      // handle agentless login page if url has login string then redirect below route
      if (isAgentPage || isAgent) {
        history.push('/tenant-login')
      } else {
        /** Redirect user to sign up app in case of a failure in login process */
        !ignoreCurrentRoute && history.push('/signup')
      }
    } finally {
    }
  }, [handleAgentContainer])

  const loadInitialSlices = async () => {
    selectDispatch(['orgs'])
    dispatch(userpreferencesSlice.thunk())
    fetched.current = true
    setBootComplete(true)
  }

  /** Initiate login on load */
  useEffect(() => {
    init()
  }, [])

  const options = useMemo(() => {
    return { headers: { Authorization: 'Bearer ' + user.apiKey } }
  }, [user.loggedIn])

  // @ts-ignore
  const { isReachable, startPolling } = useNetworkPolling('/whoami', 30000, options)

  useEffect(() => {
    if (user.loggedIn) startPolling()
  }, [user.loggedIn])

  /** load required redux states once authenticated */
  useEffect(() => {
    if (user && user.loggedIn && fetched.current === false) loadInitialSlices()
  }, [user])

  useEffect(() => {
    const orgs = Object.keys(user?.UserOrgs || {})
    const fn = async () => {
      /**
       * In case the active org doesn't exist on orgs list then, reset to default org
       */
      if (user.activeOrg && !orgs.includes(user.activeOrg)) {
        await localforage.setItem('persist:root', '')
        changeOrganization('default')
        location.reload()
      }
    }
    fn()
  }, [user])

  return (
    <BootContext.Provider value={{ bootComplete }}>
      <NetworkError
        open={!isReachable}
        Message={
          <Typography className='text-center mt-4' variant='h5'>
            Something went wrong can't connect to the server. <br />
            <br />
            Please try
            <a className='rounded p-1 bg-blue-700 mx-2' href='/'>
              reloading
            </a>
            the application.
          </Typography>
        }
        Icon={<PortableWifiOff fontSize='large' color='error' />}
      />
      {props.children}
    </BootContext.Provider>
  )
}

export default BootProvider
