import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router'
import { useUser } from 'Core/Hooks/useUser'
import { ReactFlowGraph } from 'V2Components'
import { useGraph } from './hooks/index'
import { createDataSelectorHook } from 'infra/redux'
const useSlices = createDataSelectorHook(['iamUsers', 'analyzeIdentity'])

const IdaGraph = () => {
  const { accountRefID, kind } = useParams()
  const { slices } = useSlices()
  const { user } = useUser()
  const userObj = slices.iamUsers.find((user) => user.ObjectMeta.ID === accountRefID)

  const { nodes, edges, nodesMeta, getGraphData, buildGraphData } = useGraph(
    user,
    userObj,
    kind,
    accountRefID
  )

  useEffect(() => {
    if (accountRefID) getGraphData()
  }, [accountRefID, getGraphData])

  useEffect(() => {
    buildGraphData()
  }, [buildGraphData])

  const graphComponents = useMemo(
    () => (
      <ReactFlowGraph
        nodes={nodes}
        edges={edges}
        onNodeExpandClick={(node) => {
          node.onExpand && node.onExpand()
        }}
        nodesMeta={nodesMeta}
      />
    ),
    [nodes, edges, nodesMeta]
  )

  return <div>{graphComponents}</div>
}

export { IdaGraph }
