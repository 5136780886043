import { Controls, MarkerType, ReactFlow } from '@xyflow/react'
import '@xyflow/react/dist/style.css'
import React, { useMemo, useState } from 'react'
import { CustomNode } from './components/CustomNode'
import { getReactFlowNodeOutgoers } from './utils'
import _ from 'lodash'

const nodeTypes = {
  customNode: CustomNode
}

/**
 * @typedef {{
 *  id            : string
 *  label         : string
 *  Icon          ?: any
 *  description   : string
 *  type          : string
 *  isLoading    ?: boolean
 *  collapsible  ?: boolean   //* True if the node can be collapsed/expanded
 *  customRender  ?: any
 * }} Node
 *
 * @typedef {{
 *  id            : string
 *  startID       : string
 *  endID         : string
 * }} Edge
 *
 * @param {{
 *  nodes             : Node[][]
 *  edges             : Edge[]
 *  nodesMeta         : Object.<string, {
 *    isLoading        ?: boolean
 *    isCollapsed      ?: boolean
 * }>
 *  onNodeExpandClick : ((node:Node & {
 *  columnIndex : number
 *  rowIndex    : number
 *  onExpand
 *  }) => void)
 * }} param0
 * @returns
 */

const defaultViewport = { x: 0, y: 0, zoom: 1 }
const X_AXIS_STEP = 320
const Y_AXIS_STEP = 120
const INITIAL_X_OFFSET = 50
const VERTICAL_PADDING = 100

const ReactFlowGraph = ({
  nodes,
  edges,
  onNodeExpandClick = null,
  nodesMeta = {},
  onAlert = null,
  xAxisNodeSpace = X_AXIS_STEP,
  yAxisNodeSpace = Y_AXIS_STEP
}) => {
  const [hoveredEdgeId, setHoveredEdgeId] = useState(null)

  const onEdgeMouseEnter = (event, edge) => {
    setHoveredEdgeId(edge.id)
  }

  const onEdgeMouseLeave = () => {
    setHoveredEdgeId(null)
  }
  const getFilteredNodes = () => {
    const hiddenNodeIds = []
    for (const nodeId in nodesMeta) {
      const { isCollapsed } = nodesMeta[nodeId]
      if (isCollapsed) {
        const outgoers = getReactFlowNodeOutgoers({ id: nodeId }, _.flatten(nodes), edges)
        outgoers.forEach((n) => {
          hiddenNodeIds.push(n.id)
        })
      }
    }

    const nds = []
    nodes.forEach((nodeItems, i) => {
      const visibleNodes = []
      nodeItems.forEach((node) => {
        if (!hiddenNodeIds.includes(node.id)) visibleNodes.push(node)
      })
      nds[i] = visibleNodes
    })

    return nds
  }

  const filteredNodes = useMemo(() => getFilteredNodes(), [nodes, nodesMeta])

  const generateUniqueKey = (baseId) =>
    `${baseId}-${Date.now()}-${Math.random().toString(36).slice(2, 11)}`

  const getGraphNodes = () => {
    const nd = []
    const axis = [INITIAL_X_OFFSET, 0]

    // Calculate the total height of the graph
    const maxColumnHeight = Math.max(
      ...filteredNodes.map((column) => column.length * yAxisNodeSpace)
    )
    const totalHeight = maxColumnHeight + VERTICAL_PADDING * 2

    // Calculate the starting Y position to center the graph vertically
    const startY = (800 - totalHeight) / 2 + VERTICAL_PADDING
    // console.log(filteredNodes, '=>>>>>>>')
    filteredNodes.forEach((nodeItems, i) => {
      const columnHeight = nodeItems.length * yAxisNodeSpace
      const columnStartY = startY + (maxColumnHeight - columnHeight) / 2
      let yAxis = columnStartY

      nodeItems.forEach((nodeData, j) => {
        const { id, key, type, collapsible, isLoading, ...rest } = nodeData
        nd.push({
          key: generateUniqueKey(id + type),
          id: id,
          position: { x: axis[0], y: yAxis },
          type: 'customNode',
          sourcePosition: 'right',
          targetPosition: 'left',
          data: {
            showTargetHandle: i > 0,
            showSourceHandle: i < filteredNodes.length - 1,
            id,
            targetIconType: type,
            isCollapsible: collapsible,
            isLoading: isLoading || nodesMeta[id]?.isLoading,
            meta: nodesMeta[id] || {},
            onAlert: onAlert ? () => onAlert(id) : null,
            onExpand() {
              onNodeExpandClick &&
                onNodeExpandClick({
                  ...nodeData,
                  columnIndex: i,
                  rowIndex: j
                })
            },
            ...rest
          }
        })
        yAxis += yAxisNodeSpace
      })
      axis[0] += xAxisNodeSpace
    })

    return nd
  }

  const getGraphEdges = () => {
    const edgs = []

    edges.forEach((edge) => {
      const { startID, endID } = edge
      const isHovered = startID + endID === hoveredEdgeId
      edgs.push({
        key: generateUniqueKey(startID + endID),
        id: startID + endID,
        source: startID,
        target: endID,
        markerEnd: {
          type: MarkerType.Arrow,
          width: 20,
          height: 20,
          color: isHovered ? '#ff7a2ca8' : '#7aa1db78'
        },
        style: {
          strokeWidth: isHovered ? 2.5 : 1.5,
          stroke: isHovered ? '#ff7a2ca8' : '#7aa1db78',
          transition: 'all 0.2s ease-in-out'
        }
      })
    })
    return edgs
  }

  const graphNodes = useMemo(() => getGraphNodes(), [filteredNodes, nodesMeta])
  const graphEdges = useMemo(() => getGraphEdges(), [edges, hoveredEdgeId])

  return (
    <div className='h-[800px]'>
      <ReactFlow
        nodes={graphNodes}
        edges={graphEdges}
        nodeTypes={nodeTypes}
        snapToGrid={true}
        defaultViewport={defaultViewport}
        onEdgeMouseEnter={onEdgeMouseEnter}
        onEdgeMouseLeave={onEdgeMouseLeave}
        proOptions={{ hideAttribution: true }}
      >
        <Controls />
      </ReactFlow>
    </div>
  )
}

export { ReactFlowGraph }
