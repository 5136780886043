import { Box, Button, makeStyles, Modal, Paper, Switch, TextareaAutosize, Typography } from '@material-ui/core'

import React, { useEffect, useRef, useState } from 'react'
import { debug } from 'Utils/DebugHelper'
const useStyles = makeStyles(() => ({
  root: {
    transform: 'translate(-50%)',
    margin: '10% 0 0 50%'
  },
  textArea: {
    width: '35.5rem',
    padding: '16px',
    background: '#fafafa',
    borderRadius: '8px'
  }
}))
const DebugForm = () => {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false)
  const [state, setState] = useState(debug.getAllFlags())
  const textArea = useRef('')
  const handleOpenModal = () => {
    setIsOpen(true)
  }
  const handleModalClose = () => {
    setIsOpen(false)
  }
  const onsubmit = () => {
    try {
      debug.setApiToken(textArea.current.value)
    } catch (error) {
      console.error('error while updating api token', error)
    }
  }
  const onclear = () => {
    try {
      debug.clearApiToken()
    } catch (error) {
      console.error('error while updating api token', error)
    }
  }
  const keyListener = (e) => {
    if (e.ctrlKey && e.shiftKey && e.keyCode === 191) {
      console.log('worked')
      handleOpenModal()
    }
  }
  const handleSwitch = (flag) => {
    const latestFlags = debug.setDebugFlag(flag, !state[flag])
    setState(latestFlags)
  }
  const reloadAndStartIntro = (flag) => {
  }
  useEffect(() => {
    document.addEventListener('keydown', keyListener)
    return () => document.removeEventListener('keydown', keyListener)
  }, [])
  return (
    <Modal onClose={handleModalClose} open={isOpen}>
      <Box component={Paper} width='40rem' height='auto' p={2} pt={1.5} className={classes.root}>
        <Typography variant='h4'>Debug Tools</Typography>
        {/* Error Notifications */}
        <Box display='flex' justifyContent='space-between' alignItems='center' mt={2}>
          <Typography variant='subtitle2'>1. Turn on api error notification</Typography>
          <Switch checked={state.errorNotification} onClick={() => handleSwitch('errorNotification')} />
        </Box>
        {/* Verbose error notification */}
        <Box display='flex' justifyContent='flex-start' alignItems='center' mt={0}>
          <Typography variant='caption'> Verbose error notification?</Typography>
          <Switch disabled={!state.errorNotification} size='small' checked={state.verboseErrorNotification} onClick={() => handleSwitch('verboseErrorNotification')} />
        </Box>
        {/* Mock APIs */}
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography variant='subtitle2'>2. Fallback to mock APIs on failure</Typography>
          <Switch checked={state.fallbackToMock} onClick={() => handleSwitch('fallbackToMock')} />
        </Box>
        {/* Intro js */}
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography variant='subtitle2'>2. Show application walkthrough</Typography>
          <Button color='primary' variant='contained' checked={state.intro} onClick={reloadAndStartIntro}>Reload and start intro</Button>
        </Box>
        {/* JWT update and clear */}
        <Box mt={0.5}>
          <Box mb={1}>
            <Typography variant='subtitle2'>3. Edit api token</Typography>
          </Box>
          <TextareaAutosize className={classes.textArea} ref={(ref) => { textArea.current = ref }} defaultValue={state.apiToken || ''} />
          <Box display='flex' justifyContent='space-between' alignItems='center' mt={1}>
            <Typography variant='caption' color='error'>Changing/Clearing token will reload browser</Typography>
            <Box display='flex' alignItems='center'>
              <Button onClick={onclear} variant='outlined' color='secondary' style={{ marginRight: '16px' }}>Clear Token</Button>
              <Button onClick={onsubmit} variant='contained' color='primary'>Update Token</Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default DebugForm
