import FallBack from 'Components/Fallback'
import { Redirect, Route, Switch } from 'Core'
import CheckFeatureFlag from 'Views/CheckFeatureFlag'
import NotFound from 'Views/NotFound'
import React, { Suspense } from 'react'

import { ApplicationRoutes } from 'features/applications'
import { ApprovalListRoutes } from 'features/approvals'
import { IAMResourcesRoutes } from 'features/iamResources'
import { PolicyRoutes } from 'features/policy'
import { SessionRoutes } from 'features/sessions'
import { ADDomainControllersUser, TargetsRoutes } from 'features/targets'
import { ActivityLogsRoute } from 'features/activityLogs'
import { IAMRolesRoutes } from 'features/iamRoles'
import { BundlesRoutes } from 'features/bundles'
import { DataStoreRoutes } from 'features/snowflake'
import { DevicePassKeys } from 'features/settings'

const UserDevices = React.lazy(() => import('Views/UsersDevices'))
const DeviceDetails = React.lazy(() => import('Views/DeviceList/DeviceDetails'))
const IAMActionDetailsWithContext = React.lazy(() =>
  import('Views/IamActionsAndResources/IAMActionList/IAMActionDetails')
)
const IAMActionAndResources = React.lazy(() => import('Views/IamActionsAndResources'))
const ServiceAccounts = React.lazy(() => import('Views/ServiceAccounts'))
const SlackLogin = React.lazy(() => import('features/slackLogin'))
const MsTeamLogin = React.lazy(() => import('features/msTeamsLogin'))
// TODO! Two route files ate not needed
const UserviewRoutes = () => {
  const view = 'user'
  return (
    <Suspense fallback={<FallBack />}>
      <Switch>
        <Route exact path={[`/${view}/bundles`, `/${view}/bundles/*`]} component={BundlesRoutes} />
        <Route
          exact
          path={[`/${view}/passkeys`, `/${view}/passkeys/*`]}
          component={DevicePassKeys}
        />
        <Route
          exact
          path={[`/${view}/activity-logs`, `/${view}/activity-logs/*`]}
          component={ActivityLogsRoute}
        />
        <Route exact path={[`/${view}/targets`, `/${view}/targets/*`]} component={TargetsRoutes} />
        <Route
          exact
          path={[`/${view}/resources`, `/${view}/resources/*`]}
          component={IAMResourcesRoutes}
        />
        <Route exact path={[`/${view}/policys`, `/${view}/policys/*`]} component={PolicyRoutes} />
        <Route
          exact
          path={[`/${view}/applications`, `/${view}/applications/*`]}
          component={ApplicationRoutes}
        />
        <Route
          exact
          path={[`/${view}/datacloud`, `/${view}/datacloud/*`]}
          component={DataStoreRoutes}
        />
        <Route
          exact
          path={[`/${view}/access-requests`, `/${view}/access-requests/*`]}
          component={ApprovalListRoutes}
        />
        <Route
          exact
          path={[`/${view}/session-logs`, `/${view}/session-logs/*`]}
          component={SessionRoutes}
        />
        <Route exact path={[`/${view}/addomaincontrollers`]} component={ADDomainControllersUser} />
        <Route
          exact
          path={[`/${view}/iam-roles`, `/${view}/iam-roles/*`]}
          component={IAMRolesRoutes}
        />
        <Route
          exact
          path={[`/${view}/service-accounts`, `/${view}/service-accounts/:accountType/:action`]}
        >
          <CheckFeatureFlag isRoute permission='showServiceAccountsPage'>
            <ServiceAccounts />
          </CheckFeatureFlag>
        </Route>
        <Route exact path={[`/${view}/service-accounts`, `/${view}/service-accounts/:accountType`]}>
          <CheckFeatureFlag isRoute permission='showServiceAccountsPage'>
            <ServiceAccounts />
          </CheckFeatureFlag>
        </Route>
        <Route exact path={`/${view}/`}>
          <Redirect to={`/${view}/targets`} />
        </Route>

        <Route exact path={[`/${view}/iam-actions/:accountType`]}>
          <CheckFeatureFlag isRoute permission='showIamActions'>
            <IAMActionAndResources tab='iam-actions' />
          </CheckFeatureFlag>
        </Route>
        <Route exact path={[`/${view}/account-resources/:accountType`]}>
          <CheckFeatureFlag isRoute permission='showIamActions'>
            <IAMActionAndResources tab='account-resources' />
          </CheckFeatureFlag>
        </Route>
        <Route exact path={[`/${view}/iam-policy/:accountType`]}>
          <CheckFeatureFlag isRoute permission='showIamActions'>
            <IAMActionAndResources tab='iam-policy' />
          </CheckFeatureFlag>
        </Route>
        <Route exact path={[`/${view}/iam-action/:name`, `/${view}/iam-action/:name/:action`]}>
          <CheckFeatureFlag isRoute permission='showIamActions'>
            <IAMActionDetailsWithContext />
          </CheckFeatureFlag>
        </Route>
        <Route exact path={`/${view}/devices`}>
          <CheckFeatureFlag isRoute permission='showUsersDevicesPage'>
            <UserDevices />
          </CheckFeatureFlag>
        </Route>
        <Route exact path={`/${view}/device/:id`}>
          <CheckFeatureFlag isRoute permission='showUsersDevicesPage'>
            <DeviceDetails />
          </CheckFeatureFlag>
        </Route>
        <Route
          exact
          path={[`/${view}/slack-login`, `/${view}/slack-login`]}
          component={SlackLogin}
        ></Route>
        <Route exact path={`/${view}/ms-teams-login`} component={MsTeamLogin}></Route>
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </Suspense>
  )
}

export default UserviewRoutes
