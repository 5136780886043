import { getAZUREResourceIcon } from 'Components/CustomIcons/AzureResources'

// AWS Resource Types Enum
export const AwsRsrcType = Object.freeze({
  AWS_Glue_Workflow: 'AWS_Glue_Workflow',
  AWS_Cloudwatch_Dashboard: 'AWS_Cloudwatch_Dashboard',
  AWS_Cloudwatch_Metric_Stream: 'AWS_Cloudwatch_Metric_Stream',
  AWS_SNS_Topic: 'AWS_SNS_Topic',
  AWS_Athena_Data_Catalog: 'AWS_Athena_Data_Catalog',
  AWS_Cognito_Identity_Pool: 'AWS_Cognito_Identity_Pool',
  AWS_Glue_Job: 'AWS_Glue_Job',
  AWS_Redshift_Cluster: 'AWS_Redshift_Cluster',
  AWS_GuardDuty_Detector: 'AWS_GuardDuty_Detector',
  AWS_DYNAMO_DB: 'AWS_DYNAMO_DB'
})

// Node Name Extraction
export const getNodeName = (node) => {
  const props = node?.Properties
  return (
    (props?.Label === 'Project' && props.ResourceName) ||
    (['GcpResource', 'IamAction'].includes(node?.RefKind) && (props?.ResourceName || node?.Name)) ||
    (node?.RefKind === 'AzureResource' && props?.ResourceName) ||
    (node?.RefKind === 'IamServiceAccount' && props?.DisplayName) ||
    (node?.RefKind === 'AccessKey' && (node?.Name?.split('/keys/')[1] || node?.Name)) ||
    node?.Name ||
    node?.RefKind
  )
}

// Resource Icon Handlers
export const getAWSResourceIcon = (name, type) => (AwsRsrcType[name] ? 'All_Resource' : type)

export const getGCPResourceIcon = (node) => {
  const label = node?.Properties?.Label || ''
  const iconMap = {
    Project: 'GCP_PROJECT',
    'Compute Instance': 'GCP_COMPUTE_INSTANCE',
    Organization: 'GCP_ORG',
    GcpResource: 'GCP',
    'Folder|0': 'GCP_GOOGLE_FOLDER',
    'Folder|1': 'GCP_GOOGLE_FOLDER',
    'Folder|2': 'GCP_GOOGLE_FOLDER',
    'Folder|3': 'GCP_GOOGLE_FOLDER',
    'Folder|4': 'GCP_GOOGLE_FOLDER',
    'Folder|5': 'GCP_GOOGLE_FOLDER',
    'Folder|6': 'GCP_GOOGLE_FOLDER'
  }
  return iconMap[label] || 'GCP'
}

export const getAzureResourceIcon = (node) =>
  ({
    Subscription: 'AZURE_SUBSCRIPTION_TYPE',
    'Resource Group': 'AZURE_RESOURCE_GROUP_TYPE',
    'CDN Profile': 'AZURE_CDN_PROFILES',
    'Virtual Machine': 'AZURE_VIRTUAL_MACHINE',
    'Managed Cluster': 'AZURE_MANAGED_CLUSTER',
    'Data Factory': 'AZURE_DATA_FACTORIES',
    'Storage Account': 'AZURE_STORAGE',
    'Logic App Workflow': 'AZURE_LOGIC_APPS',
    'Redis Caches': 'AZURE_CACHE_REDIS',
    'Cosmos DB Account': 'AZURE_COSMOS_DB_ACCOUNT',
    'Web Apps': 'AZURE_WEB_APPS',
    Monitor: 'AZURE_MONITOR',
    'Keyvault Managed HSM': 'AZURE_KEY_VAULTS',
    Keyvault: 'AZURE_KEY_VAULTS',
    'Cosmos Cassandra Cluster': 'AWS_COMPUTE_PARALLEL_CLUSTER',
    'Cosmos PostgreSQL Cluster': 'AWS_COMPUTE_PARALLEL_CLUSTER',
    'Container Registry': 'AZURE_CONTAINER_REGISTRIES'
  }[node?.Properties?.Label] || 'AZURE')

// Node Type and Name Handlers
export const getNodeType = (node, type) =>
  ({
    Account: type,
    IamUser: 'USER',
    IamGroup: 'USERGROUPS',
    IamRole: 'ROLE',
    IamFederatedUser: 'FEDERATED_USER',
    AccessKey: 'ACCESS_KEY',
    IamPolicy: 'POLICY',
    ExpandableNode: getAWSResourceIcon(node.Name, type),
    AwsResource: type,
    IamAction: type ? 'GCP' : 'AZURE',
    GcpResource: getGCPResourceIcon(node),
    AzureResource: getAzureResourceIcon(node),
    IamServiceAccount: 'SERVICEACCOUNT'
  }[node.RefKind] || 'All_Resource')

export const getNodeTypeName = (node, kind) =>
  ({
    DummyUser: 'User',
    DummyCloud: 'Account',
    Account: 'Account',
    IamUser: 'User',
    IamGroup: 'Group',
    IamFederatedUser: 'Federated User',
    IamRole: 'Role',
    IamServiceAccount: 'Service Account',
    IamAction: 'Role',
    IamPolicy: 'Policy',
    AccessKey: kind === 'IamServiceAccount' ? 'Service Account Key' : 'Access Key',
    Resources: 'Resources',
    ExpandableNode: 'Aws Resources',
    AwsResource: 'Aws Resource',
    GcpResource: getGCPResourceIcon(node)?.replace('GCP_', ''),
    AzureResource: node?.Properties?.Label,
    Organization: 'Organization',
    'Folder|0': 'Folder',
    Project: 'Project',
    'Compute Instance': 'Compute Instance'
  }[node.RefKind] || null)

// Utility Functions
export const generateUniqueKey = (frefID, frefKind, trefID, trefKind) =>
  `${frefID}-${frefKind}-${trefID}-${trefKind}`

export const getNodeKey = (refID, refKind) => `${refID}-${refKind}`

// Constants
export const apiEndpoints = Object.freeze({
  IamUser: 'iamusers',
  IamGroup: 'iamgroups',
  IamServiceAccount: 'iamserviceaccounts',
  IamRole: 'iamroles'
})

// Risk Level Calculator
export const getRiskLevel = (riskValue) =>
  riskValue >= 0 && riskValue <= 3.9
    ? 'low'
    : riskValue <= 6.9
    ? 'medium'
    : riskValue <= 8.9
    ? 'high'
    : riskValue <= 10
    ? 'critical'
    : 'none'

// Search Filter
export const filterRowsBySearchQuery = (rows, searchQuery) =>
  searchQuery
    ? rows.filter((row) =>
        [row.email, row.name].some((field) =>
          field?.toLowerCase().includes(searchQuery.toLowerCase())
        )
      )
    : rows
