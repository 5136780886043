import { faExclamationTriangle, faUser, faUserGear } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { filterRowsBySearchQuery, getRiskLevel } from 'features/IdentityAnalyzer/utils'
import { createDataSelectorHook } from 'infra/redux'
import moment from 'moment'
import { Button, DataTable, RiskIndicator, TargetIcon, Tooltip } from 'procyon-ui'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { ShimmerTableRow } from 'Utils/ShimmerEffect'
import { getAccount } from '../../utils'

const useslices = createDataSelectorHook(['iamServiceAccounts', 'accountList'])
const ServiceAccountTable = ({ searchQuery }) => {
  const { slices } = useslices()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(true)
  const [tableRows, setTableRows] = useState([])
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 15,
    page: 0
  })

  useEffect(() => {
    if (!slices || slices.iamServiceAccounts.length === 0) {
      setIsLoading(true)
    }

    if (slices?.iamServiceAccounts?.length > 0 && tableRows.length === 0) {
      const rows = slices.iamServiceAccounts
        .slice()
        .map((obj, index) => {
          const transformedObj = {
            id: index + 1,
            platform: 'GCP',
            name:
              obj.Spec.GcpIamServiceAccountSpec.Email ||
              obj.Spec.GcpIamServiceAccountSpec.DisplayName,
            createdate: '',
            riskResources: obj.Spec.RiskScore,
            account: getAccount(slices.accountList, obj.Spec.Account.RefID, 'name'),
            accountRef: obj.ObjectMeta.ID,
            finding: obj.Spec.GcpIamServiceAccountSpec
          }

          const findingsLabels = generateFindingsLabels({ row: transformedObj })
          transformedObj.findingsLength = findingsLabels.length

          return transformedObj
        })
        .sort((a, b) => {
          if (a.findingsLength > b.findingsLength) return -1
          if (a.findingsLength < b.findingsLength) return 1

          const dateA = moment(a?.createdate, 'MM-DD-YY HH:mm:ss')
          const dateB = moment(b?.createdate, 'MM-DD-YY HH:mm:ss')
          if (dateA.isBefore(dateB)) return 1
          if (dateA.isAfter(dateB)) return -1

          return 0
        })

      setTableRows(rows)
      setIsLoading(false)
    }
  }, [slices])

  const handleRedirectRemedyDetails = (id, cloudType) => {
    history.push(
      `/admin/idaanalyzer/IamServiceAccount/${encodeURIComponent(id)}/${encodeURIComponent(
        cloudType
      )}`
    )
  }

  const filteredRows = filterRowsBySearchQuery(tableRows, searchQuery)

  const handleGraphRedirection = (accountRef) => {
    history.push(`/admin/idaanalyzer/idagraph/IamServiceAccount/${encodeURIComponent(accountRef)}`)
  }

  const generateFindingsLabels = (data) => {
    const userData = data?.row?.finding
    const labels = []
    const addedLabels = new Set()

    // Analyzer Findings Labels for GCP
    if (data.row.platform === 'GCP') {
      const gcpAnalyzerFindings = Object.keys(userData?.AnalyzerFindings?.Map || {}).length
        ? Object.fromEntries(
            Object.entries(userData?.AnalyzerFindings?.Map).map(([key, value]) => {
              try {
                return [key, JSON.parse(value)] // Parse each value
              } catch (error) {
                console.error(`Failed to parse value for key "${key}":`, error)
                return [key, value] // Use original value if parsing fails
              }
            })
          )
        : {}

      gcpAnalyzerFindings &&
        Object.keys(gcpAnalyzerFindings).forEach((key) => {
          const finding = gcpAnalyzerFindings[key]
          // UnusedPermission Label (Clickable)
          if (finding.Type === 'UnusedPermission' && !addedLabels.has('UnusedPermission')) {
            labels.push(
              <Tooltip title='Unused Permission' key={`UnusedPermission-${key}`}>
                <span className='bg-white shadow-md rounded-full w-[30px] h-[30px] flex items-center justify-center mr-3'>
                  <FontAwesomeIcon icon={faExclamationTriangle} />
                </span>
              </Tooltip>
            )
            addedLabels.add('UnusedPermission')
          }

          // UnusedIAMRole label
          if (finding.Type === 'UnusedIAMRole' && !addedLabels.has('UnusedIAMRole')) {
            labels.push(
              <Tooltip key={`UnusedIAMRole-${key}`} title='Unused Iam Role'>
                <span className='bg-white shadow-md rounded-full w-[30px] h-[30px] flex items-center justify-center mr-3'>
                  <FontAwesomeIcon icon={faUserGear} />
                </span>
              </Tooltip>
            )
            addedLabels.add('UnusedIAMRole')
          }
        })
    }

    return labels
  }

  return (
    <>
      <DataTable
        autoHeight={true}
        pageSizeOptions={[5, 10, 25, 50, 100]}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        columns={[
          {
            field: 'name',
            headerName: 'Name',
            renderCell: (param) =>
              isLoading ? (
                <ShimmerTableRow />
              ) : (
                <>
                  <span className='mr-2'>
                    <FontAwesomeIcon icon={faUser} />
                  </span>
                  <Tooltip title={param.value}>
                    <p className='font-medium'>{param.value}</p>
                  </Tooltip>
                </>
              ),
            width: 480
          },
          {
            field: 'createdate',
            headerName: 'Creation Date',
            renderCell: (param) =>
              isLoading ? (
                <ShimmerTableRow />
              ) : (
                <Tooltip title='Date and Time Format Is MM-DD-YY HH-MM-SS'>
                  <p className='font-medium'>{param.value}</p>
                </Tooltip>
              ),
            width: 130
          },
          {
            field: 'account',
            headerName: 'Account',
            renderCell: (param) =>
              isLoading ? <ShimmerTableRow /> : <p className='font-medium'>{param.value}</p>,
            width: 120
          },
          {
            field: 'platforms',
            headerName: 'Platforms',
            renderCell: (param) =>
              isLoading ? <ShimmerTableRow /> : <TargetIcon width='30px' type={'GCP'} />,
            width: 150
          },
          {
            field: 'riskResources',
            headerName: 'Risk Resources',
            renderCell: (params) =>
              isLoading ? (
                <ShimmerTableRow />
              ) : (
                <RiskIndicator variant={getRiskLevel(params.value)} />
              ),
            width: 160
          },
          {
            field: 'graph',
            headerName: 'Graph',
            renderCell: (param) =>
              isLoading ? (
                <ShimmerTableRow />
              ) : (
                <div className='bg-white shadow-md rounded-full w-[35px] h-[35px] flex items-center justify-center'>
                  <img
                    onClick={() => handleGraphRedirection(param.row.accountRef)}
                    src='img/icons/organization-chart.png'
                    alt=''
                    className='w-5 cursor-pointer'
                  />
                </div>
              ),
            width: 130
          },
          {
            field: 'finding',
            headerName: 'Findings',
            renderCell: (param) =>
              isLoading ? <ShimmerTableRow /> : <>{generateFindingsLabels(param)}</>,
            width: 230
          },
          {
            field: 'accountRef',
            headerName: 'Recommended Action',
            renderCell: (param) =>
              isLoading ? (
                <ShimmerTableRow />
              ) : (
                <>
                  {generateFindingsLabels(param).length > 0 && (
                    <span
                      onClick={() => handleRedirectRemedyDetails(param.value, param.row.platform)}
                    >
                      <Button variant='secondary'>Action</Button>
                    </span>
                  )}
                </>
              ),
            width: 220
          }
        ]}
        rows={
          isLoading
            ? Array(1).fill({
                id: '',
                name: '',
                createdate: '',
                lastused: '',
                platforms: '',
                riskResources: ''
              })
            : filteredRows
        }
      />
    </>
  )
}

export { ServiceAccountTable }
